<template>
  <div class="dashboard" v-show="pageShow">
    <b-notification
      type="is-warning"
      aria-close-label="Close notification"
      v-if="job.status == '4' && job.our_remark"
    >
      {{ job.our_remark }}
    </b-notification>
    <section class="hero is-small is-primary mt-2 job-hero">
      <div class="hero-body">
        <div class="container">
          <p class="title">{{ job.title }}</p>
          <p class="subtitle">
            <a
              target="_"
              :href="'https://www.iulaanu.com' + employer.get_absolute_url"
              >{{ employer.name }}
              <i class="ml-2 fa-solid fa-arrow-up-right-from-square"></i
            ></a>
          </p>
          <p class="is-6">Deadline to apply: {{ fdeadline }}</p>
        </div>
        <div class="controls columns mt-2" v-if="!jobClosed()">
          <div class="column">
            <b-button
              class="mb-2 mr-2"
              type="is-primary"
              :loading="isLoading"
              icon-left="pencil"
              outlined
              inverted
              @click="editJob(job.id)"
            >
              Edit
            </b-button>
            <b-button
              class="mb-2 mr-2"
              type="is-primary"
              :loading="isLoading"
              icon-left="rocket-launch"
              outlined
              inverted
              @click="publishJob(job.id)"
              v-if="job.status == '2' || job.status == '4'"
            >
              Publish
            </b-button>
            <b-button
              class="mb-2 mr-2"
              type="is-primary"
              :loading="isLoading"
              outlined
              inverted
              icon-left="alert-circle"
              v-if="job.status == '1'"
              @click="closeJob(job.id)"
            >
              Close job
            </b-button>
            <!-- <b-button
              class="mb-2 mr-2"
              type="is-primary"
              :loading="isLoading"
              outlined
              inverted
              icon-left="delete"
              v-if="job.status !== '1'"
              @click="deleteJob(job.id)"
            >
              Delete
            </b-button> -->
          </div>
        </div>
      </div>
    </section>
    <div class="columns mt-4 mb-6">
      <div class="column">
        <div class="box">
          <div class="key-info mb-4">
            <div class="columns">
              <div class="column is-half">
                <div class="columns is-mobile">
                  <div class="column is-half-mobile desc" v-if="job.location">
                    <strong>Location</strong>
                    <br />
                    <i class="fa-solid fa-location-dot"></i>
                    {{ job.location.name }}
                  </div>
                  <div class="column is-half-mobile desc" v-if="job.job_type">
                    <strong>Job type</strong>
                    <br />
                    <i class="fa-solid fa-business-time"></i>
                    {{ job.job_type.name }}
                  </div>
                </div>
                <div class="columns is-mobile">
                  <div class="column is-half-mobile desc" v-if="job.salary">
                    <strong>Salary</strong>
                    <br />
                    <i class="fa-solid fa-money-bill-wave"></i>
                    {{ job.salary.name }}
                  </div>
                  <div class="column is-half-mobile desc">
                    <strong>Reference</strong>
                    <br />
                    <i class="fa-solid fa-retweet"></i>
                    {{ job.ref_no ? job.ref_no : "None" }}
                  </div>
                </div>
              </div>
              <div class="column is-half">
                <div class="columns is-mobile">
                  <div class="column is-half-mobile desc" v-if="job.sector">
                    <strong>Sector</strong>
                    <br />
                    <i class="fa-solid fa-city"></i>
                    {{ job.sector.name }}
                  </div>
                  <div class="column is-half-mobile desc">
                    <strong>Vacancies</strong>
                    <br />
                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                    {{ job.vacancies }}
                  </div>
                </div>
                <div class="columns is-mobile">
                  <div
                    class="column is-half-mobile desc"
                    v-if="job.min_experience"
                  >
                    <strong>Min experience</strong>
                    <br />
                    <i class="fa-solid fa-user-clock"></i>
                    {{ job.min_experience.name }}
                  </div>
                  <div
                    class="column is-half-mobile desc"
                    v-if="job.min_qualification"
                  >
                    <strong>Min Qualification</strong>
                    <br />
                    <i class="fa-solid fa-user-graduate"></i>
                    {{ job.min_qualification.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- job description -->
        <div class="box">
          <div class="columns">
            <div class="column is-12">
              <h3 class="title is-6 mb-3">Description</h3>
              <div class="desc-text" v-html="job.description"></div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="columns">
            <div class="column is-12">
              <h3 class="title is-6 mb-3">How to apply</h3>
              <div class="desc-text" v-html="job.how_to_apply"></div>
            </div>
          </div>
        </div>
        <div class="box" v-if="job.ref_link">
          <div class="columns">
            <div class="column is-12">
              <h3 class="title is-6 mb-3">Additional Details</h3>
              <p class="desc-text mb-3">
                Checkout the links
                <a :href="job.ref_link" target="_blank">here</a> for more
                details.
              </p>
            </div>
          </div>
        </div>
        <div class="box" v-if="job.attachement">
          <div class="columns">
            <div class="column is-12">
              <h3 class="title is-6 mb-3">Attachment</h3>
              <p class="desc-text mb-3">
                Click below button to download the attachment.
              </p>
              <a
                class="button is-primary is-rounded"
                target="_blank"
                :href="job.attachement"
              >
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="column is-4">
        <div
          class="box job-box sticky"
          style="min-height: 60vh"
          id="employer-info"
        >
          <div class="columns is-mobile m-2">
            <div class="column is-one-quarter">
              <div class="media-left">
                <figure class="image is-64x64">
                  <img :src="employer.logo" alt="Logo" />
                </figure>
              </div>
            </div>
            <div class="column is-three-quarters-mobile">
              <div class="mb-1 title is-5">
                <strong>{{ employer.name }}</strong>
              </div>
              <div class="mb-4">{{ employer.island.name }}</div>
            </div>
          </div>
          <div class="columns m-2">
            <div class="column">
              <div class="intro desc-text mb-5">{{ employer.intro }}</div>
              <div class="mb-4 desc-text">
                <i class="fa-solid fa-city"></i>
                {{ employer.sector.name }}
              </div>
              <div class="mb-4 desc-text">
                <i class="fa-solid fa-users"></i>
                {{ employer.org_size.name }}
              </div>
              <div class="mb-4 desc-text">
                <i class="fa-solid fa-envelope"></i>
                {{ employer.email }}
              </div>
              <div class="mb-4 desc-text">
                <i class="fa-solid fa-phone"></i>
                {{ employer.phone }}
              </div>
              <div class="mb-4 desc-text">
                <i class="fa-solid fa-globe"></i>
                {{ employer.website }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {
    //
  },
  data() {
    return {
      job: {},
      employer: {},
      isLoading: false,
      pageShow: false,
    };
  },
  computed: {
    // date to format 1 January 2022
    fdeadline() {
      return new Date(this.job.deadline).toLocaleDateString("en-GB");
    },
  },
  methods: {
    jobClosed() {
      if (new Date(this.job.deadline) < new Date() && this.job.status === "1") {
        return true;
      } else {
        return false;
      }
    },
    async fetchJob(id) {
      const response = await axios.get(`/api/jobs/${id}/`);
      this.job = response.data;
    },
    getEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
    dateToString(date) {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    async checkEmployerReady() {
      //current employer
      const employer = JSON.parse(localStorage.getItem("employer"));
      // check if employer is ready to post jobs
      const isReady = await axios.get(`/api/employers/${employer.id}/ready/`);
      // after response, check if employer is ready to post jobs
      if (isReady.data.ready) {
        return true;
      } else {
        // buefy toast open message
        this.$buefy.toast.open({
          message: isReady.data.message,
          type: "is-danger",
          queue: false,
          duration: 5000,
        });
        //navigate to previous route
        return false;
      }
    },
    async publishJob(id) {
      // check if job deadline is greater than today
      if (new Date(this.job.deadline) < new Date()) {
        // buefy toast open message
        this.$buefy.toast.open({
          message: "Job deadline must be later than today",
          type: "is-danger",
          queue: false,
          duration: 5000,
        });
        return;
      }
      // check if employer is ready to post jobs
      if (await this.checkEmployerReady()) {
        // publish job
        await axios
          .post(`/api/job/publish/${id}/`)
          .then((response) => {
            if (response.status === 200) {
              // fetch job and update store jobs
              this.$store
                .dispatch("jobs/fetchJobs", this.getEmployer().id)
                .then(() => {
                  this.$router.push({ name: "EmployerJobs" });
                  // toast
                  this.$buefy.toast.open({
                    message:
                      "Submitted successfully. Job will be visible on site after we review it.",
                    type: "is-success",
                    duration: 5000,
                  });
                });
            } else {
              this.$buefy.toast.open({
                message: "Something went wrong. Please try again",
                type: "is-danger",
                duration: 3000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$buefy.toast.open({
              message: "Something went wrong. Please try again",
              type: "is-danger",
              duration: 3000,
            });
          });
      } else {
        // do nothing
      }
    },
    // deleteJob(id) {
    //   this.$buefy.dialog.confirm({
    //     title: "Delete Job",
    //     message: "Are you sure you want to delete this job?",
    //     confirmText: "Delete",
    //     cancelText: "Cancel",
    //     type: "is-danger",
    //     hasIcon: true,
    //     onConfirm: async () => {
    //       await axios.delete(`/api/job/delete/${id}/`).then((response) => {
    //         if (response.status === 200) {
    //           // fetch jobs and update store jobs
    //           this.$store
    //             .dispatch("jobs/fetchJobs", this.getEmployer().id)
    //             .then(() => {
    //               this.$router.push({ name: "EmployerJobs" });
    //               this.$buefy.toast.open({
    //                 message: "Job deleted successfully",
    //                 type: "is-success",
    //                 duration: 5000,
    //               });
    //             });
    //         } else {
    //           this.$buefy.toast.open({
    //             message: "Something went wrong. Please try again",
    //             type: "is-danger",
    //             duration: 3000,
    //           });
    //         }
    //       });
    //     },
    //     onCancel: () => {
    //       //
    //     },
    //   });
    // },
    closeJob(id) {
      this.$buefy.dialog.confirm({
        title: "Close Job",
        message: "Are you sure you want to close applications for this job?",
        confirmText: "Close",
        cancelText: "Cancel",
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          await axios.post(`/api/job/close/${id}/`).then((response) => {
            if (response.status === 200) {
              // fetch jobs and update store jobs
              this.$store
                .dispatch("jobs/fetchJobs", this.getEmployer().id)
                .then(() => {
                  this.$router.push({ name: "EmployerJobs" });
                  this.$buefy.toast.open({
                    message: "Job closed successfully",
                    type: "is-success",
                    duration: 5000,
                  });
                });
            } else {
              this.$buefy.toast.open({
                message: "Something went wrong. Please try again",
                type: "is-danger",
                duration: 3000,
              });
            }
          });
        },
        onCancel: () => {
          //
        },
      });
    },
    editJob(id) {
      this.$router.push({ name: "JobEdit", params: { id: id } });
    },
  },
  created() {
    this.$store.dispatch("setLoading", true);
    this.fetchJob(this.$route.params.id).then(() => {
      this.employer = this.getEmployer();
      this.$store.dispatch("setLoading", false);
      this.pageShow = true;
    });
  },
};
</script>

<style scoped>
.job-hero {
  box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.11),
    0 1.6px 3.6px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
}
.side-bar {
  position: relative;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 2rem;
}

.job-box {
  border-color: transparent;
  color: rgba(0, 0, 0, 0.7);
}
</style>
